import React, { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://paperform.co/__embed.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []); // Empty array ensures this effect runs only once

  return (
    <div className="App">
      <header className="App-header">
        <img src={`${process.env.PUBLIC_URL}/BATTLE_AGENTS_Logo.png`} alt="logo" className="app-logo img-responsive" />
      </header>
      <div data-paperform-id="o3apfcdj" style={{ marginTop: '0' }}></div>
    </div>
  );
}

export default App;
